<template>
	<div ref="editor" :class="{'rich-editor' : true, editable: editable}">
		<div :class="{ 'format-bar': true, 'is-focused' : focused }">
			<button :class="{ 'is-active': editor.isActive('bold') }" @click="editor.chain().focus().toggleBold().run()">
				B
			</button>

			<button :class="{ 'is-active': editor.isActive('italic') }" @click="editor.chain().focus().toggleItalic().run()">
				I
			</button>

			<button :class="{ 'is-active': editor.isActive('underline') }" @click="editor.chain().focus().toggleUnderline().run()">
				U
			</button>

			<template v-if="!inline && !editor.isActive('table')">
				<button :class="{ 'is-active': editor.isActive('link') }" tabindex="-1" @click="setUrl">
					<IconHyperlink :width="16" :height="16" />
				</button>

				<button :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()">
					H1
				</button>

				<button :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
					H2
				</button>

				<button :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()">
					H3
				</button>

				<button :class="{ 'is-active': editor.isActive('paragraph') }" @click="editor.chain().focus().toggleParagraph().run()">
					P
				</button>

				<button :class="{ 'is-active': editor.isActive('bulletList') }" @click="editor.chain().focus().toggleBulletList().run()">
					&bullet;
				</button>

				<button :class="{ 'is-active': editor.isActive('orderedList') }" @click="editor.chain().focus().toggleOrderedList().run()">
					1.
				</button>

				<button :class="{ 'is-active': editor.isActive('alphabeticList') }" @click="editor.chain().focus().toggleAlphabeticList().run()">
					a.
				</button>

				<button :class="{ 'is-active': editor.isActive('table') }" @click="editor.chain().focus().insertTable().run()">
					<IconTable :width="16" :height="16" />
				</button>
			</template>

			<template v-if="!inline && editor.isActive('table')">
				<button tabindex="-1" @click="editor.chain().focus().addColumnBefore().run()">
					<IconAddColBefore :width="16" :height="16" />
				</button>

				<button tabindex="-1" @click="editor.chain().focus().addColumnAfter().run()">
					<IconAddColAfter :width="16" :height="16" />
				</button>

				<button tabindex="-1" @click="editor.chain().focus().deleteColumn().run()">
					<IconDeleteCol :width="16" :height="16" />
				</button>

				<button tabindex="-1" @click="editor.chain().focus().addRowBefore().run()">
					<IconAddRowBefore :width="16" :height="16" />
				</button>

				<button tabindex="-1" @click="editor.chain().focus().addRowAfter().run()">
					<IconAddRowAfter :width="16" :height="16" />
				</button>

				<button tabindex="-1" @click="editor.chain().focus().deleteRow().run()">
					<IconDeleteRow :width="16" :height="16" />
				</button>

				<button tabindex="-1" @click="editor.chain().focus().deleteTable().run()">
					<IconDeleteTable :width="16" :height="16" />
				</button>

				<button tabindex="-1" @click="editor.chain().focus().mergeCells().run()">
					<IconMergeTable :width="16" :height="16" />
				</button>
			</template>

			<slot :editor="editor" :focused="focused" />
		</div>

		<editor-content class="editor" :editor="editor" v-on="$listeners" />
	</div>
</template>

<script>
	import {Editor, EditorContent} from '@tiptap/vue-2';
	import HardBreak from '@tiptap/extension-hard-break';
	import Heading from '@tiptap/extension-heading';
	import Bold from '@tiptap/extension-bold';
	import Italic from '@tiptap/extension-italic';
	import Underline from '@tiptap/extension-underline';
	import Placeholder from '@tiptap/extension-placeholder';
	import History from '@tiptap/extension-history';
	import BulletList from '@tiptap/extension-bullet-list';
	import OrderedList from '@tiptap/extension-ordered-list';
	import ListItem from '@tiptap/extension-list-item';
	import Table from '@tiptap/extension-table';
	import TableHeader from '@tiptap/extension-table-header';
	import TableCell from '@tiptap/extension-table-cell';
	import TableRow from '@tiptap/extension-table-row';
	import Link from '@tiptap/extension-link';
	import Paragraph from '@tiptap/extension-paragraph';
	import Text from '@tiptap/extension-text';
	import Document from '@tiptap/extension-document';
	import AlphabeticList from '../inc/editor-extensions/alphabetic-list';

	import IconAddColBefore from './icons/IconAddColBefore';
	import IconAddColAfter from './icons/IconAddColAfter';
	import IconAddRowBefore from './icons/IconAddRowBefore';
	import IconAddRowAfter from './icons/IconAddRowAfter';
	import IconDeleteCol from './icons/IconDeleteCol';
	import IconDeleteRow from './icons/IconDeleteRow';
	import IconDeleteTable from './icons/IconDeleteTable';
	import IconTable from './icons/IconTable';
	import IconMergeTable from './icons/IconMergeTable';
	import IconHyperlink from './icons/IconHyperlink';
	import HyperlinkModal from './HyperlinkModal';
	import striptags from 'striptags';
	import Bus from '../inc/bus';
	import {v4} from 'uuid';
	import {jsonToHtml} from '../inc/text';

	const allowedTags = [
		'b',
		'i',
		'u',
		'strong',
		'em',
		'br',
		'h1',
		'h2',
		'h3',
		'ul',
		'ol',
		'li',
		'span',
		'p'
	];

	export default {
		components: {
			EditorContent,
			IconAddColBefore,
			IconAddColAfter,
			IconAddRowBefore,
			IconAddRowAfter,
			IconDeleteCol,
			IconDeleteRow,
			IconTable,
			IconDeleteTable,
			IconMergeTable,
			IconHyperlink
		},
		props: {
			value: {
				type: [
					Object,
					String
				],
				required: true
			},
			blockId: {
				type: String,
				required: true
			},
			inline: {
				type: Boolean,
				default: false
			},
			placeholder: {
				type: String,
				default: 'Write something...'
			},
			extensions: {
				type: Array,
				default: () => []
			},
			pasteTags: {
				type: Boolean,
				default: true
			},
			editable: {
				type: Boolean,
				default: true
			}
		},
		saveTimeout: null,
		data() {
			return {
				hyperlink: '',
				focused: false,
				keepInBounds: true,
				currentSelection: null,
				editor: new Editor({
					content: jsonToHtml(this.value),
					editable: this.editable,
					extensions: this.getExtensions(),
					// Disable any external drag-and-drop
					editorProps: {
						handleDOMEvents: {
							drop: (view, e) => {
								e.preventDefault();
							}
						},
						transformPastedHTML: this.applyPasteRules
					},
					onUpdate: () => {
						this.clearSaveTimeout();

						this.$options.saveTimeout = setTimeout(() => {
							this.save();
						}, 3000);
					},
					onFocus: () => {
						this.focused = true;
					},
					onBlur: () => {
						this.focused = false;
						this.save();
					}
				})
			};
		},
		beforeDestroy() {
			this.editor.destroy();
		},
		methods: {
			save() {
				if(!this.clearSaveTimeout()) {
					return;
				}

				this.$emit('input', this.editor.getJSON());
			},
			setUrl() {
				const {doc, selection} = this.editor.state;
				const {from, to} = selection;
				let marks = [];

				if(from === to) {
					Bus.emit('error', 'To insert a link you first need to select some text.');

					return;
				}

				doc.nodesBetween(from, to, node => {
					marks = [
						...marks,
						...node.marks
					];
				});

				const mark = marks.find(markItem => markItem.type.name === 'link');
				let inputUrl = '';

				if(mark && mark.attrs.href) {
					inputUrl = mark.attrs.href;
				}

				this.$modal.show(HyperlinkModal, {
					url: inputUrl,
					handleUpdate: url => {
						if(typeof url !== 'string') {
							return;
						}

						if(url && !url.match(/^[http|\\/|#|\\?]/u)) {
							this.editor.commands.setLink({href: 'https://' + url});
						}
						else {
							this.editor.commands.setLink({href: url});
						}
					},
					handleRemove: () => {
						this.editor.commands.unsetLink();
					}
				}, {height: 'auto'});
			},
			clearSaveTimeout() {
				if(this.$options.saveTimeout) {
					clearTimeout(this.$options.saveTimeout);
					this.$options.saveTimeout = null;

					return true;
				}

				return false;
			},
			getExtensions() {
				let extensions = [
					Paragraph,
					Text,
					Document,
					Bold,
					Italic,
					Underline,
					History,
					HardBreak,
					Placeholder.configure({
						emptyEditorClass: 'is-editor-empty',
						emptyNodeClass: 'is-empty',
						emptyNodeText: this.placeholder,
						showOnlyWhenEditable: true,
						showOnlyCurrent: true
					})
				];

				if(!this.inline) {
					extensions = [
						...extensions,
						Heading.configure({
							levels: [
								1,
								2,
								3
							]
						}),
						BulletList,
						OrderedList,
						AlphabeticList,
						ListItem,
						Table.configure({resizable: true}),
						TableHeader,
						TableCell,
						TableRow,
						Link
					];
				}

				return extensions.concat(this.extensions);
			},
			applyPasteRules(html) {
				const el = document.createElement('div');

				el.innerHTML = html;

				const options = el.querySelectorAll('span.options');

				if(options.length) {
					options.forEach(option => {
						option.dataset.id = v4();
					});
				}

				if(this.pasteTags) {
					return striptags(el.innerHTML, allowedTags);
				}

				return striptags(el.innerHTML);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.rich-editor {
		position: relative;

		&.editable {
			.format-bar::v-deep {
				&.is-focused {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}

	.format-bar::v-deep {
		position: absolute;
		bottom: 100%;
		left: 0;
		transform: translateY(-5px);
		z-index: 10;
		background: $color__dark;
		color: $color__white;
		box-shadow: $box_shadow;
		display: flex;
		height: 40px;
		transition: opacity 0.2s, visibility 0.2s;
		visibility: hidden;
		opacity: 0;
		border-radius: $border_radius;
		overflow: hidden;

		button {
			width: 40px;
			height: 40px;
			background: none;

			&:hover {
				background: rgba($color__white, 0.1);
			}

			&.is-active {
				background: $color__link;
			}
		}
	}

	.editor::v-deep {
		.ProseMirror {
			background: $color__white;
			font-size: 1.25rem;
			width: 100%;
			border: none;
			box-shadow: $box_shadow__input;
			border-radius: $border_radius;
			padding: 0.5rem 1rem;
			margin-bottom: $default_padding / 2;
			line-height: 1em;
			outline: none !important;
			transition: box-shadow 0.2s ease-out;
		}

		.ProseMirror-focused {
			@include inner-border(1px, $color__light-blue);
		}

		.selectedCell {
			background-color: rgba($color: $color__light_blue, $alpha: 0.2);
		}

		h2 {
			font-size: 1.3em;
			margin-top: 1.5em;
			margin-bottom: 0.25em;
		}

		h3 {
			font-size: 1em;
			line-height: $line__height;
			margin: 0;
		}

		li {
			margin-bottom: 0.6em;
		}

		a {
			pointer-events: none;
			cursor: text;
		}

		:first-child {
			margin-top: 0;
		}

		:last-child {
			margin-bottom: 0;
		}

		p.is-editor-empty:first-child::before {
			content: attr(data-empty-text);
			float: left;
			color: #aaa;
			pointer-events: none;
			height: 0;
			font-style: italic;
		}

		table p {
			margin: 0;
		}
	}
</style>