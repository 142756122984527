<template>
	<div class="hyperlink-modal">
		<label for="hyperlink-modal-url">Update hyperlink</label>
		<input id="hyperlink-modal-url" v-model="hyperlink" type="url" />

		<BaseButton type="primary" @click="update">
			Update
		</BaseButton>

		<BaseButton type="secondary" @click="remove">
			Remove
		</BaseButton>
	</div>
</template>

<script>
	import BaseButton from './BaseButton';

	export default {
		name: 'HyperlinkModal',
		components: {
			BaseButton
		},
		props: {
			url: {
				type: String,
				required: true
			},
			handleUpdate: {
				type: Function,
				required: true
			},
			handleRemove: {
				type: Function,
				required: true
			}
		},
		data() {
			return {
				hyperlink: this.url
			};
		},
		methods: {
			update() {
				this.handleUpdate(this.hyperlink);

				this.$modal.hideAll();
			},
			remove() {
				this.handleRemove();

				this.$modal.hideAll();
			}
		}
	};
</script>

<style lang="scss" scoped>
	label {
		display: block;
		font-size: 1.25em;
		margin-bottom: 1em;
	}

	input {
		margin-bottom: $default_padding;
	}
</style>