import OrderedList from '@tiptap/extension-ordered-list';

const AlphabeticList = OrderedList.extend({
	name: 'alphabeticList',

	defaultOptions: {
		HTMLAttributes: {
			type: 'a'
		}
	},

	addCommands() {
		return {
			toggleAlphabeticList: () => ({commands}) => commands.toggleList('alphabeticList', 'listItem')
		};
	},

	addKeyboardShortcuts() {
		return {
			'Mod-Shift-Alt-7': () => this.editor.commands.toggleAlphabeticList()
		};
	}
});

export default AlphabeticList;