<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" :width="width" :height="height"><g stroke-width="1" :fill="_secondaryfill" :stroke="_secondaryfill"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M12,9l1.975-2.025 c1.367-1.367,1.367-3.583,0-4.95l0,0c-1.367-1.367-3.583-1.367-4.95,0L7,4" :stroke="fill" /> <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M9,12l-2.025,1.975 c-1.367,1.367-3.583,1.367-4.95,0l0,0c-1.367-1.367-1.367-3.583,0-4.95L4,7" :stroke="fill" /> <line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="5" y1="11" x2="11" y2="5" /> </g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>